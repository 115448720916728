<template>
  <div class="row mb-3">
    <epi
      :intEpiId="this.$route.params.intId"
      :intTipoEquipamentoId="1342"
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import Epi from "@/components/documento/pgr/equipamento/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { Epi },
  beforeDestroy() {},
  mounted() {
    if (!this.$route.params.intId) {
      this.$router.push({ name: "pgr.epi" });
    }
  },
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
